<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/offer-scheme`"
                title="Offer"
                :columns="columns"
                routerpath="/setup/addoffer"
                :formOptions="formOptions"
                :newPage="true"
                :viewFields="viewFields"
              >
              <template  #allCustomFields='{props}' >
                <span v-if="props.column.label === 'Project'" v-html="setProject(props.row)">
                </span>
                <span v-else-if="props.column.label === 'Land'" v-html="setLand(props.row)">
                </span>
                 <span v-else-if="props.column.field === 'enddate'">
                {{ moment(props.row.enddate ?props.row.enddate :props.row.modifieddatetime ).format('DD/MM/YYYY') }}
              </span>
              </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
import moment from 'moment';
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      moment,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Offer' : 'Add Offer'}`,
        submitRouterPath: '/setup/offer',
        method: 'post',
        action: 'add',
        getEditValue: `${baseApi}/get-offer-scheme-By-Id`,
        url: `${baseApi}/offer-scheme`,
        gridForm: true,
        inputFields: [
      ]
      },
      viewFields:[
        {
          label: 'Type',
          field: 'type',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Description',
          field: 'description',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Project',
          field: 'project_id',
          class: "col-md-4",
          type:'text'
        },
        {
          label: 'Startdate',
          field: 'startdate',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Enddate',
          field: 'enddate',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Discount',
          field: 'value',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Image',
          field: 'image',
          type: 'attachment',
          class: "col-md-4",
        },
      ],
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search ID",
        //   },
        // },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Type'
          }
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Type'
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search description'
          }
        },
        {
          label: 'Project',
          field: 'project_id',
          type: 'dropdown',
          url: 'getallproject',
          responseValue:'projectname',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Project'
          },
          width: '200px',
        },
        {
          label: 'Land',
          field: 'land_id',
          type: 'dropdown',
          url: 'getvillagedata',
          responseValue:'villagename_newsurvayno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Project'
          },
          width: '200px',
        },
        {
          label: 'Discount',
          field: 'value',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Discount'
          }
        },
        {
          label: 'Enter By',
          field: 'enterby',
          type: 'staticdropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },

        {
          label: 'End date',
          field: 'enddate',
          type:'datetime',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Modified By',
          field: 'modifiedby',
          type: 'staticdropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Modified Date',
          field: 'updated_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        // {
        //   label: "Image",
        //   field: "image",
        // },
        {
          label: 'Action',
          field: 'action'
        }
      ]
    }
  },
  mounted () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  },
  computed:{
    setProject() {
      return (row) => {
        let str = ''
        if (row.projects.length > 0){
          let arr = row.projects.map((item) => item.projectname ? item.projectname : '')
          arr = arr.filter(item => item);
          str = arr.join(',<br>')
        }
        return str
      }
    },
    setLand(){
    return (row) => {
        let str = ''
          if (row.lands.length > 0){
            let arr = row.lands.map((item) => item.villagename ? item.villagename.villagename +' (' + item.surveynonew +')': '')
            arr = arr.filter(item => item);
            str = arr.join(',<br>')
          }
        return str
      }
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
